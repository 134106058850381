import * as React from "react"
import {graphql, withPrefix} from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../../components/PageHeaderBlock";
import {Col, Container, Row} from "react-bootstrap";
import ArticleWidget from "../../components/widgets/ArticleWidget";
import ArticleWidget2 from "../../components/widgets/ArticleWidget2";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useEffect, useRef, useState} from "react";
import copy from 'clipboard-copy';

const generatePassword = (length, complexity) => {
    let charset = '';
    if (complexity === 'lowest') {
        charset = 'abcdefghijklmnopqrstuvwxyz';
    } else if (complexity === 'low') {
        charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    } else if (complexity === 'medium') {
        charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    } else if (complexity === 'high') {
        charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
    } else if (complexity === 'highest') {
        charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
    }

    let password = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }

    return password;
};

export default function PasswordGenerator() {
    const { t } = useTranslation();
    const [passwordLength, setPasswordLength] = useState(8);
    const [complexity, setComplexity] = useState('low');
    const [generatedPassword, setGeneratedPassword] = useState('');
    const inputRef = useRef(null);

    const handleGeneratePassword = () => {
        const password = generatePassword(passwordLength, complexity);
        setGeneratedPassword(password);
    };

    const handleInputClick = () => {
        // Выбираем текст внутри инпута при клике
        inputRef.current.select();
    };

    const handleCopyToClipboard = () => {
        if (inputRef.current) {
            const inputValue = inputRef.current.value;
            copy(inputValue)
                .then(() => alert('Текст скопирован в буфер обмена'))
                .catch(() => alert('Не удалось скопировать текст в буфер обмена'));
        }
    };

    useEffect(() => {
        handleGeneratePassword();
    }, []);

    return (
        <Layout>
            <Seo title={t('passwordGenerator')} description={t('passwordGeneratorH1')} image="/posts/services/password-generator.png" />
            <PageHeaderBlock h1="passwordGeneratorH1" />
            <Container fluid className="px-flex">
                <Breadcrumbs firstChild="ourServicesWord" firstChildUrl={'/services'} secondChild="passwordGenerator" />
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col xxl={2} className="mb-2 mb-xl-3 mb-xxl-0 pb-1 pb-xl-0"><ArticleWidget /></Col>
                    <Col md={8} xxl={7}>
                        <Row className="password-generator mb-4 mb-xl-5">
                            <Col md={6} xl={4} className="d-flex align-items-center justify-content-center py-3 py-md-4 py-xl-5">
                                {t('lengthWord')}:
                                <input
                                    type="number"
                                    value={passwordLength}
                                    className="ms-2 ms-xl-3 w-25 text-center"
                                    onChange={(e) => setPasswordLength(Math.max(1, parseInt(e.target.value, 10)))}
                                />
                            </Col>
                            <br />
                            <Col md={6} xl={4} className="d-flex align-items-center justify-content-center py-3 py-md-4 py-xl-5">
                                {t('сomplexityWord')}:
                                <select
                                    value={complexity}
                                    onChange={(e) => setComplexity(e.target.value)}
                                    className="ms-2 ms-xl-3"
                                >
                                    <option value="lowest">{t('simpleWord')}</option>
                                    <option value="low">{t('lowWord')}</option>
                                    <option value="medium">{t('averageWord')}</option>
                                    <option value="high">{t('highWord')}</option>
                                </select>
                            </Col>
                            <Col md={12} xl={4} className="d-flex align-items-center justify-content-center py-3 py-md-4 py-xl-5">
                                <button className="btn__big" onClick={handleGeneratePassword}>{t('generatePassword')}</button>
                            </Col>
                        </Row>
                        {generatedPassword && (
                            <Col className="d-md-flex justify-content-center align-items-center flex-wrap flex-xl-nowrap py-1 py-md-2 py-xl-4 mb-3 mb-xl-4 mb-xxl-5">
                                <strong className="d-block d-md-inline text-center mt-3">{t('generatedPassword')}:</strong>
                                <div className="mt-3 text-center">
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        value={generatedPassword}
                                        className="text-center ms-2 ms-xl-3 me-3"
                                        onClick={handleInputClick}
                                    />
                                    <button className="btn btn__big outline small" onClick={handleCopyToClipboard}>
                                        <span className="d-none d-md-inline">{t('copyWord')}</span>
                                        <svg className="svg-icon d-md-none">
                                            <use xlinkHref={withPrefix(`sprite.svg#copy-icon`)}></use>
                                        </svg>
                                    </button>
                                </div>
                            </Col>
                        )}
                    </Col>
                    <Col md={4} xxl={3}><ArticleWidget2 /></Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;